:root {
  font-family: 'Oxygen', sans-serif;
  --primary: #000000;
  --secondary: rgb(22, 22, 22);
  --text-colour: #f0f0f0;  
  --highlight: rgb(31, 136, 221);
  --highlight2:  rgb(11, 94, 161);

}
body {
  /* background-color: black; */
  background: linear-gradient(-45deg, #000000, #131313);
/* height: 100vh; */
  color: var(--text-colour);
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

a {
  color: var(--highlight);
}

main {
  display: flex;
  flex-direction: column;


  
  justify-content: center;
  text-align: center;
  align-items: center;

  margin-bottom: 100px;
  height: 100%;
}

img {
  width: 200px;
  margin-bottom: 20px;
  align-self: center;
}

a.button {
  margin: 10px 0;
  color: black;
  background-color: white;
  padding: 10px;
  width: 200px;
  text-decoration: none;
  display: flex;
  text-align: center;
  justify-content: center;
}

a svg {
  height: 1em;
  width: 1em;
  margin-right: 5px;
  align-self: center;
}

hr {
  width: 200px;
}

main p {
  width: 400px;
}

table {
  width: 50%;
  border: 2px solid #ddd;
  border-collapse: collapse;
}

td {
  /* width: 50%; */
  border: 2px solid #ddd;
}

li {
text-align: left;
}

/* Footer */

footer {
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 100%;
  background-color: var(--secondary);
  
  display: flex;
  /* flex-direction: column; */
  text-align: center;
  justify-content: center;

}

footer a {
  color: white;
  text-decoration: none;
  align-self: center;
  margin: 0 10px;
  display: flex;
  text-align: center;
  justify-content: center;
}

footer a svg {
  height: 1em;
  width: 1em;
  
}

/* BUTTON */

.button.float  {
  display: inline-block;
  border-radius: 4px;
  background-color: var(--text-colour);
  border: none;
  color: var(--secondary);
  text-align: center;
  font-size: 21px;
  padding: 10px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button.float span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button.float span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button.float:hover span {
  padding-right: 25px;
}

.button.float:hover span:after {
  opacity: 1;
  right: 0;
}

/* SLIDER */

.input-container {
  width: 100%; /* Width of the outside container */
  /* animation: fadeInOut 0.5s ease-in-out; */
  /* opacity: 0; */
  /* transition: opacity 0.3s ease-in-out; */
}

.input-container.active {
  opacity: 1;
  pointer-events: auto;
}

.input-container div {
  text-align: center;
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
  /* align-content: center; */
  /* justify-items: center; */
}


/* .input-slide {
  animation: fadeInOut 0.5s ease-in-out;

}

.fade-out {
  opacity: 0;
  transition: all 250ms linear 2s; 
} */



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}

.textarea {
  margin: 20px 0;
  color: var(--primary);
  background-color: var(--text-colour);
  border: none;
  outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 18px;
  font-family: inherit;
}

.slider-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 20px 0;
}

.slider-container label {
  padding: 0 5px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
}

.slider-div {
  width: 50%;
  /* max-width: 500px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider {
  -webkit-appearance: none;
  /* width: 100%; */
  height: 15px;
  border-radius: 5px;  
  background: var(--text-colour);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  /* transition: opacity .2s; */


}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: var(--highlight);
  cursor: pointer;
  transition: background 200ms ease-in-out;

}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--highlight);
  cursor: pointer;
  transition: background 200ms ease-in-out;

}

.slider::-webkit-slider-thumb:hover {
  background: var(--highlight2);
  transition: background 200ms ease-in-out;
}

/* FORM */

.page-container {
  width: 100%;
  min-height: calc(100vh - 124px);
  /* margin-top: 15%; */
  /* background-color: var(--secondary); */
  flex-direction: column;
  display: flex;
  /* padding: 20px 80px; */
  /* justify-content: center; */
  align-content: center;
  text-align: center;
  /* flex-wrap: wrap; */

}

.page-container.center {
  justify-content: center;
}

.page-container h1.title {
  font-size: 60px;
  font-style: italic;
  margin: 50px 0;
}

.form-navigation {
  position: absolute;
  bottom: 20%;
  /* left: 50%; */
  /* transform: translate(-55%, 0); */
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.form-navigation button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: var(--primary);
  border: none;
  cursor: pointer;
  background-color: var(--text-colour);
  width: 100px;
  margin: 10px 10px;
}

.form-navigation button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.form-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.form-toggle button {
  padding: 20px 0px;
  font-size: 16px;
  font-weight: bold;
  color: var(--primary);
  border: none;
  cursor: pointer;
  background-color: var(--text-colour);
  width: 200px;
  margin: 10px 5px;
}

.form-toggle button:disabled {
  background-color: var(--highlight);
  border: 4px solid var(--highlight2);
  cursor: default;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button.float  {
  display: inline-block;
  border-radius: 0px;
  background-color: var(--text-colour);
  border: none;
  color: var(--secondary);
  text-align: center;
  font-size: 21px;
  padding: 10px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button.float span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button.float span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button.float:hover span {
  padding-right: 25px;
}

.button.float:hover span:after {
  opacity: 1;
  right: 0;
}

/* .program-table {
  margin-top: 20px;
}

.program-table th {
  background-color: var(--text-colour);
  color: var(--primary);
} */

.restday {
  background-color: var(--secondary);
  padding: 10px 0;
  width: 100%;
}

.program-table {
  width: 80%;
  border-collapse: collapse;
  /* margin-bottom: 20px; */
}

.program-table .header {
  width: 100%;
  text-align: center;
  justify-content: center;
  background-color: var(--text-colour);
  color: var(--primary);
}

.program-table th {
  border: 2px solid #ccc;
}

.program-table th {
  border: 0px solid #ccc;
}

.program-table tr {
  border: 0px solid #ccc;
  border-bottom-width: 2px;
  padding: 8px;
  text-align: center;
}

.exercise,
.superset,
.notes {
  padding: 10px;
}

.set-cell,
.superset-cell {
  /* width: 35%; */
  position: relative;
  border: 0px solid #ccc;

}

.notes-cell {
  width: 45%;
}

.notes-cell .notes {
  /* border-left: 2px solid #ccc; Left border for notes */
  padding-left: 15px; /* Offset for the border */
}

/* Media */

.table-reg td {
  width: 50%;
}

.pros-cons-table th {
  background-color: var(--text-colour);
  color: var(--primary);
}

.table-mbl {
  display: none;
}

.main {
  width: 100%;
  height: 60vh;
  /* position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0); */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* @media screen and (min-width: 768px) {

} */

@media screen and (max-width: 768px) {
  /* .main {
    top: 20%;
  } */

  p {
    padding-right: 20px; padding-left: 20px;
  }
  .slider-div {
    width: 80%;
  }

  table {
    width: 85%;
  }

  main p {
    width: auto;
  }

  .table-mbl {
    display: table;
  }
  .table-reg {
    display: none;
  }

  .set-cell,
  .superset-cell,
  .notes-cell {
    display: block;
    width: 100%; /* Set width to fill the available space */
    margin-bottom: 10px; /* Add some space between cells */
  }

  .notes-cell {
    border: none;
  }

  .form-navigation {
    bottom: 10%;
  }
}

